import React from 'react'

import Markdown from '../../markdown'
import * as Types from '../../types'

const article: Types.Article = {
  title: 'Text Automaton',
  date: new Date('Jun 07 2016').getTime(),
  url: 'text-automaton',
  feedContent: (
    <Markdown>{`
    <gif aspect="1.75" src="2016-6-07-Tautomata/tauto"/>

    *A cellular automaton following semantic word connections. [See Demo](/media/2016-6-07-Tautomata/index.html)*

    Created for artist [Joel Swanson](http://joelericswanson.com/), this is a [cellular automaton](https://en.wikipedia.org/wiki/Cellular_automaton) that grows connections between semantically related words. The grid starts blank and the user can type any word, then hit enter. At this point the automaton will start. Each cell has knowledge about it's current letter, and makes a guess about what word that letter could be a part of. The cells can also talk to their neighbors, learning about their letters and word guesses. The cells have some tendency towards agreeing with their neighbors, and are especially eager if their words are synonyms of each other.

    These *relatively* simple rules acting on individual cells produce a stunning emergent behavior when viewed as a group. Given a prompt, the automaton will expand on it semantically and spatially in a feedback loop. Different words because of their alphabetic or semantic structure may produce very different branching behavior.

    The implementation includes a dictionary of synonyms for the 50k most common english words. With clever use of data structures, the automaton can execute in real time, in the browser. [Check it out.](/media/2016-6-07-Tautomata/index.html)

    Source [on Github](https://github.com/satchelspencer/text-automata).
`}</Markdown>
  ),
  tags: [],
}

export default article
