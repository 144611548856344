import React from 'react'

import Markdown from '../markdown'
import * as Types from '../types'
import { COMMAND } from './splash'

const article: Types.Article = {
  title: '10. Advanced Controls',
  shortTitle: '10. Controls II',
  url: 'adv-controls',
  feedContent: (
    <Markdown>{`
  <video remote="https://player.vimeo.com/video/417924293"/>
  
  **Goal:** *Understand more advanced usage of custom controls.*

  ## Relative vs Absolute Controls
  You may have noticed that when you bind a control to the volume of a track, moving the control won't affect the volume parameter of the track. This is because by default the control is *Relative*. Think of the volume parameter of the track as the trim, or the baseline level of the track. Any controls that are applied to that parameter will use that baseline as a starting point. Here's what that gives you:

   - You can have one track parameter bound to multiple controls.
   - You can set the baseline level of the track, without worrying about the controls messing it up.
   - You can reuse the same controls for multiple tracks.
  
  You can also make the control *Absolute*, meaning that the control is directly linked to its parameter. You might prefer this, especially if you have a midi controller with [two-way control](/repsyps/midi). Note that when you're using absolute controls, they won't be reset when you start or reset a scene, so you'll have to take care to make sure they have the right values before playing the track. You can also use cues to set the starting value of a parameter as discussed in [advanced cues](/repsyps/cues)

  ## Control Binding Tips
   - Using the hotkey \`A\` will start the control selection as usual, but you can use the hotkey \`B\` to select a control and make it *negative* all in one go. You can A and B your way through adding a bunch of custom controls very quickly once you're used to it.
   - Scenes can also be bound to controls. Adding a scene control simply controls the volume of all the tracks in that scene. You can select the current scene, and the previous scene by clicking on the scene header while selecting a control.
   - You can also bind controls to tracks in a previous scene. Instead of being listed as "track 1, 2 or 3" they will show up as something like "track -1". this can be useful in some cases, but may violate some of the [principles of reusability](/repsyps/library)
   - Once you jump into a scene, you can select the tracks in that scene, but you won't have access to the previous scene's controls. For now, stepping into a scene is a *one-way* action. If you step into a scene and decide you don't want to be there, you can import another scene and delete the current scene with \`${COMMAND} delete\`.

`}</Markdown>
  ),
  tags: [],
  contentPrompt: 'written version...',
  //loadContent: () => import('./content'),
}

export default article
