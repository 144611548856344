import React from 'react'

import Markdown from '../markdown'
import * as Types from '../types'
import { COMMAND } from './splash'

const article: Types.Article = {
  title: '3. Mixes and Mashups',
  shortTitle: '3. Mix & Mashup',
  url: 'mashup',
  feedContent: (
    <Markdown>{`
  <video remote="https://player.vimeo.com/video/417875868"/>
  
  **Goal:** *Use automatic source separation to create a mashup of two songs.*

  ## Automatic Acapellas / Instrumentals
  Repsyps includes an a.i. based automatic source separator called [spleeter](https://github.com/deezer/spleeter). Given any track it can split it into two new sources, one with only the vocals and one with only the instrumental. Since these new sources are perfectly synched with the original, it's super easy to create mashups, and quickly experiment with different combinations.

  **Yes!** *you can use official acapellas and instrumentals and sync them up in the same way. This is discussed below.*

  ## Mashup Example
  If you've been following along with this tutorial series, you probably already have a couple of tracks and scenes. For this, let's start fresh. Use \`file > new project\` or \`${COMMAND} shift N\` to make a totally new project. (If you really like what you made previously, save it \`file > save project\`). 
   - Now let's select a couple of tracks we want to use, one for its instrumental, one for it's vocals. 
   - As discussed in the [first tutorial](/repsyps/grid), import both tracks and add their time grids.
   - Select the track you'd like to use for vocals, then click \`separate source\` in the track details pane. This starts the automatic separation, depending on the speed of your computer it may take about a minute to separate a 4 minute song. (you only have to do this once)
   - Once the separation has finished you'll see two new source names and faders at the top of the track details: "vocal - your track name" and "instru - your track name". These correspond to the vocal only and instrumental only versions of the track. The original is still there.
   - Try playing with the faders of the new *sources* while playing the song. By sliding up and down the sources you can fade in and out the vocals and instrumental. Since we want to keep the vocals of this song and nothing else, let's mute every source except the vocal one.
   - Now go to an instrumental section of your second song, select a little loop then start playing it, then play your separated vocals... there you go.

  **keep experimenting** *just because it's on beat doesn't mean it will sound good.*

  ## Manual Track Sources
  Of course, you're not limited to adding the automatically generated sources for a song. You can add your own manually, or use a multi-track format like [stems](https://www.stems-music.com/).    Adding your own is pretty simple:
   - Under the last sources you'll find the \`add source\` button. This lets you pick a new source to add to the main track.
   - Sometimes you might get lucky, and they'll be in sync, but very often you'll have to line up the new subtrack with the original since they probably don't start at the exact same time.
   - Bring up the volume on the new source so you can hear both tracks, then under "time grid" click edit. At the bottom you'll see a list of all your sources and their offsets, probably \`0.00\`.
   - Click on the offset value you want to change for your new source. Now you'll see that source's waveform superimposed on the main waveform in red. \`shift click\` and drag to move that waveform until their peaks line up.
   - In some cases, the acapella version of a song will be at a different speed, or be arranged totally differently. That being the case, you'll have to line up the acapella with the original in some audio editing software before you can use it.

  **you may ask**

  > Why do we have to sync up the acapella to the main track? Couldn't we just add the acapella by itself and play that?

  Yes you could, but the main reason to add the acapella as a source to the main track is to piggyback off the main track's time grid for syncing the acapella with other songs. It's often much easier to lay out a time grid on a track with instrumentation. If you can hear the rhythm in an acapella accurately enough, *more power to you.*
`}</Markdown>
  ),
  tags: [],
  contentPrompt: 'written version...',
  //loadContent: () => import('./content'),
}

export default article
