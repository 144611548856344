import React from 'react'

import Markdown from '../markdown'
import * as Types from '../types'
import { COMMAND } from './splash'

const article: Types.Article = {
  title: '6. Recording Tracks',
  shortTitle: '6. Recording Tracks',
  url: 'recording',
  feedContent: (
    <Markdown>{`
  <video remote="https://player.vimeo.com/video/417884562"/>
  
  **Goal:** *Use repsyps' built-in recording utility.*

  ## Why Record in Repsyps
  If you're recording your output for documentation purposes, your best bet is probably to route repsyps' output to an audio recording utility. However, since recording in repsyps *also records the time grid* it may be useful for:

   - creating a pre-recorded mix, building it up one song at a time.
   - recording a loop from a track that doesn't line up with the time grid.
   - using repsyps as a strange sampler.

  To open the recording utility use \`file > record\` or \`${COMMAND} shift R\`. At the base of the tracks list you'll see the recorder. Hit the record button and the output waveform will start scrolling by. Once you're done, hit the record button again and you'll be prompted where to save your recording. Then the recording will be imported to repsyps, *along with its time grid*.

  ## Recording a Specific Loop or Chop
  Perhaps you want to loop part of a song off the grid lines, or jump between samples without having to perform that part of it live. This is the primary use case for recording in repsyps. Just start the recording, and play your loop exactly how you want it. Then because repsyps maintains the time grid, you can use that recording in a mix just like any other track.

  ## Creating a Pre-Recorded Mix
  Repsyps can also append a new recording to an existing track using \`file > record from track\`. This will result in a new track with the previous track and everything you just recorded added to the end. In this mode, the recording will start on the next time grid, so there may be a delay after you hit record before it starts. Have the track you're appending to selected and playing before you start recording. One use case for this is to slowly build up a pre-recorded mix. When you want to add another song, start recording from the existing mix and transition to the next song. If you want to redo it, just discard the new recording and try the transition again.

`}</Markdown>
  ),
  tags: [],
  contentPrompt: 'written version...',
  //loadContent: () => import('./content'),
}

export default article
