import React from 'react'

import Markdown from '../markdown'
import * as Types from '../types'

const article: Types.Article = {
  title: '9. Advanced Time Grid Techniques',
  shortTitle: '9. Time Grid II',
  url: 'adv-grid',
  feedContent: (
    <Markdown>{`
  <video remote="https://player.vimeo.com/video/417925983"/>
  
  **Goal:** *Setup the time grid for tracks with irregular timing or indistinct beats.*

  ## The Problem
  Some tracks are performed by imperfect humans *(wow)*, or have tempo changes or acoustic properties that mess with repsyps' automatic time grid finding. 
  
  > So what actually happens when you click \`infer\` to automatically find the grid?

  Repsyps looks at the selected region of the track and tries to extrapolate it forwards and backwards. If you selected 1 measure that's 5.1s long. It will assume that the next measure starts at the end of your selection and also lasts 5.1s, and so on. Repsyps also uses *beat snapping*, to try and align the edges of the inferred measures to where repsyps finds beats, often time fixing small tempo variations and imperfections. If this doesn't work for a track, it's almost always one of the following scenarios:
   - **very "imperfect" tempo** maybe performed live, maybe it's jazz, or j dilla.
   - **hard to find beats** maybe heavy metal or other noisy genres.
   - **tempo change** the track speeds up or slows down beyond what beat snapping can correct for.
   - **false positive beat detection** part of the track with no percussion appears to the snapping as beats.

  ## The Solution
  All of these cases are fixable, you aren't boxed into perfect time grids. So how do we fix this?
   - Start editing the time grid by hitting \`Edit\` under "time grid".
   - Go to the last place in the track where the grid was on beat. *note that when in edit mode you have to double click to select and listen to a whole measure*
   - Look at the next measure with the beat error. You can manually click and drag the edges of the grid to make it line up again.
   - Instead of having to do this with every following measure you can select the fixed measure (double click) and click the infer \`right >\` button. This will keep all of your work to the left of the selection and extrapolate forwards with your corrected information.
   - Continue this until you get through the part of the track you want to use.

  Sometimes the repsyps beat snapping is actually the source of the problem. You can toggle it on and off using the magnet icon at the top right of the time grid editing menu or using \`alt s\` or \`view > snapping\`. With snapping off it's very easy for the time grid to drift off beat over the course of a track, so you should be very precise in selecting your first measure.

  ## Pitch Changes
  If a track has a very inconsistent time grid (some measures are longer than others), then with repsyps' default time stretching you'll notice unpleasant changes up and down in pitch as it makes every measure the same length during playback. You can fix this issue by using the pitch-preserving timestretch for that track. Turn on \`Preserve Pitch\` in the track details pane. Preserving pitch comes at the cost of more cpu usage, but fixes this sort of problem nicely.

`}</Markdown>
  ),
  tags: [],
  contentPrompt: 'written version...',
  //loadContent: () => import('./content'),
}

export default article
