import React from 'react'
import ctyled from 'ctyled'

import * as icons from './index'

const Icon = ctyled((props: {name: string}) => {
  const Component = icons[props.name]
  return <Component {...props} />
}).extendSheet`
  & [fill] {
    fill:${({ color }) => color.fg};
  }
  & [stroke] {
    fill:none;
    stroke:${({ color }) => color.fg};
  }
`

export default Icon


export const IconWrapper = ctyled.div.styles({padd: 2, alignSelf: 'center'}).extendSheet`
display:block;
& svg [fill="#000"] {
  fill:${({ color }) => color.fg};
}
& svg [stroke="#000"] {
  fill:none;
  stroke:${({ color }) => color.fg};
}
`