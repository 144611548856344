import React from 'react'

import Markdown from '../markdown'
import * as Types from '../types'

const article: Types.Article = {
  title: '12. Beyond Syncronization',
  shortTitle: '12. Beyond Sync',
  url: 'nosync',
  feedContent: (
    <Markdown>{`
  <video remote="https://player.vimeo.com/video/417874856"/>
  
  **Goal:** *Learn techniques to transition between tracks without keeping the tempo.*

  For everything we've done so far we've kept the assumption that our global sync tempo never changes. When we transition between tracks, we keep them in sync by speeding them up or slowing them down to match the global tempo exactly. This doesn't always have to be the case. 

  ## Transitions Without Rhythm
  If you want, you can disable track synchronization at any time in the track details pane under "sync". Clicking \`off\` will play the track at its normal speed, but out of sync with the rest of the project.  You might want to do this when transitioning during part of a track with no distinct rhythm. In that case, you can start with sync off in the new track, then once it's the only track playing, click \`sync lock\`. This brings the project in sync with the track, changing the global tempo to line up with the previously out of sync track. This way you can go back to mixing between tracks in sync (now at the tempo of the new track).

  ## Global Tempo Change
  Of course you can also change the global tempo at any time using the slider or buttons at the top of repsyps. If you're going to change the speed slowly over time it may be imperceptible, especially if you are using the pitch preserving timestretch. Without pitch preserving, speeding up the whole project will cause the pitch to drift up or down. This could be the effect you want, but you can avoid it by turning on \`preserve pitch\` in the track details pane.

  ## Cues Without Sync
  If you add a cue while synchronization is turned off, that cue will always start playing out of sync. This can be useful for sound effects or samples that you want to play exactly when you trigger them, regardless of the global sync. If you really want to use repsyps as a sampler, you kinda can...

`}</Markdown>
  ),
  tags: [],
  contentPrompt: 'written version...',
  //loadContent: () => import('./content'),
}

export default article
