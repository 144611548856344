import React, { memo, useRef } from 'react'
import ctyled, { active } from 'ctyled'
import { keyframes } from 'react-emotion'

import { useRouter } from '../router'
import Icon from '../icons/icon'
import bg from './bg'
import { AspectWrapper, AspectInner } from '../markdown'
import useMeasure from '../measure'

const wiggle = keyframes`
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
`

const Corner = ctyled.a.styles({}).extendSheet`
  position:absolute;
  top:0;
  right:0;
  width:10%;
  height:10%;
  cursor:pointer;
  &:hover .octo-arm{
    animation: ${wiggle} 560ms ease-in-out;
  }
`

const GithubCorner = () => {
  return (
    <Corner href="https://github.com/satchelspencer/repsyps" target="_blank">
      <svg
        viewBox="0 0 250 250"
        style={{
          fill: 'gainsboro',
          color: 'black',
          position: 'absolute',
          top: 0,
          border: 0,
          right: 0,
          width: '100%',
        }}
        aria-hidden="true"
      >
        <path d="M0,0 L115,115 L130,115 L142,142 L250,250 L250,0 Z"></path>
        <path
          d="M128.3,109.0 C113.8,99.7 119.0,89.6 119.0,89.6 C122.0,82.7 120.5,78.6 120.5,78.6 C119.2,72.0 123.4,76.3 123.4,76.3 C127.3,80.9 125.5,87.3 125.5,87.3 C122.9,97.6 130.6,101.9 134.4,103.2"
          fill="currentColor"
          style={{ transformOrigin: '130px 106px' }}
          className="octo-arm"
        ></path>
        <path
          d="M115.0,115.0 C114.9,115.1 118.7,116.5 119.8,115.4 L133.7,101.6 C136.9,99.2 139.9,98.4 142.2,98.6 C133.8,88.0 127.5,74.4 143.8,58.0 C148.5,53.4 154.0,51.2 159.7,51.0 C160.3,49.4 163.2,43.6 171.4,40.1 C171.4,40.1 176.1,42.5 178.8,56.2 C183.1,58.6 187.2,61.8 190.9,65.4 C194.5,69.0 197.7,73.2 200.1,77.6 C213.8,80.2 216.3,84.9 216.3,84.9 C212.7,93.1 206.9,96.0 205.4,96.6 C205.1,102.4 203.0,107.8 198.3,112.5 C181.9,128.9 168.3,122.5 157.7,114.1 C157.9,116.9 156.7,120.9 152.7,124.9 L141.0,136.5 C139.8,137.7 141.6,141.9 141.8,141.8 Z"
          fill="currentColor"
          className="octo-body"
        ></path>
      </svg>
    </Corner>
  )
}

const RepsypsSplashWrapper = ctyled.div.styles({
  color: (c) => c.absLum(0.4).contrast(0.1),
  align: 'center',
  justify: 'center',
  column: true,
  gutter: 2,
}).extend`
  position:absolute;
  background:black;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  overflow:hidden;
  font-family:'PT Sans', sans-serif;
`

const RepsypsBg = ctyled.div.styles({}).extend`
  position:absolute;
  top:-10px;
  left:-10px;
  right:-10px;
  bottom:-10px;
  background-size: cover;
  background-position: 50% 50%;
  filter: blur(3px);
  opacity:0.4;
`

const Content = ctyled.div.styles({
  bg: true,
  rounded: true,
  border: true,
  color: (c) => c.invert(),
}).extendSheet`
  overflow:hidden;
`

const Inner = ctyled.div.styles({
  column: true,
  gutter: 2,
}).extendSheet`
  @media (orientation: landscape){
    width:90vh;
  }
  @media (orientation: portrait){
    width:90vw;
  }
`

const Actions = ctyled.div.styles({
  gutter: 2,
})

const Action = ctyled.div
  .class(active)
  .attrs<{ wide?: boolean }>({ wide: false })
  .styles({
    height: 4,
    hover: (_, p) => (p && p.wide ? 0.2 : 0.5),
    size: (s) => s * 2,
    bg: true,
    color: (c, p) =>
      p && p.wide ? c.nudge(0.05).contrast(-0.1) : c.absLum(0.5).contrast(-0.05).invert(),
    border: true,
    flex: 1,
    rounded: 0.5,
    align: 'center',
    justify: (_, props) => (props && props.wide ? 'center' : 'flex-start'),
    padd: 1.5,
    gutter: 1.5,
  })

const ActionV = ctyled.div.styles({
  column: true,
  gutter: 0.5,
})

const ActionTitle = ctyled.div.styles({
  size: (s) => s * 0.8,
  align: 'center',
}).extend``

const ActionDesc = ctyled.div.styles({
  size: (s) => s * 0.5,
}).extend`display:block;`

export const DlLink = ctyled.span
  .class(active)
  .styles({ hover: 1, color: (c) => c.as(['red', 'red']) }).extendSheet`
  font-weight:bold;
  opacity:0.9;
  display:inline-block;
  &:hover{
    text-decoration:underline;
  }
`

export const av = navigator.appVersion
export const os = av.includes('Mac') ? 'mac' : av.includes('Win') ? 'windows' : 'other'
export const COMMAND = os === 'mac' ? 'Command' : 'Ctrl'

export const NotesLink = ctyled.span
  .class(active)
  .styles({ hover: 1, size: (s) => s / 2 }).extendSheet`
font-weight:bold;
opacity:0.8;
display:inline-block;
text-decoration:underline;
&:hover{
  text-decoration:none;
}
`

const REPSYPS_VERSION = '0.0.3'

export function Download(props: { wide?: boolean }) {
  const handleMac = (e) => {
      e.stopPropagation()
      window.location.href = `https://d1p4j3i2t4q5mq.cloudfront.net/repsyps/repsyps-${REPSYPS_VERSION}.dmg`
    },
    handleWin = (e) => {
      e.stopPropagation()
      window.location.href = `https://d1p4j3i2t4q5mq.cloudfront.net/repsyps/repsyps%20Setup%20${REPSYPS_VERSION}.exe`
    }
  return (
    <Action
      wide={!!props.wide}
      onClick={(e) => (os === 'mac' ? handleMac(e) : handleWin(e))}
    >
      <Icon name="download" data-fill styles={{ height: 2.5, width: 2.5 }} />
      <ActionV>
        <ActionTitle>
          {props.wide ? 'Download ' : ''}REPSYPS 0.0.3&nbsp;
          <NotesLink
            onClick={(e) => {
              e.stopPropagation()
              window.location.href =
                'https://github.com/satchelspencer/repsyps/releases/tag/v' +
                REPSYPS_VERSION
            }}
          >
            what's new?
          </NotesLink>
        </ActionTitle>
        <ActionDesc styles={{ flex: props.wide ? 'none' : 1 }}>
          {os === 'mac' ? (
            <>
              Available for <DlLink onClick={handleMac}>MacOs</DlLink> or{' '}
              <DlLink onClick={handleWin}>Windows</DlLink>
            </>
          ) : (
            <>
              Available for <DlLink onClick={handleWin}>Windows</DlLink> or{' '}
              <DlLink onClick={handleMac}>MacOs</DlLink>
            </>
          )}
        </ActionDesc>
      </ActionV>
    </Action>
  )
}

const RepsypsSplash = memo(() => {
  const { go } = useRouter(),
    contentRef = useRef<any>(null),
    { width } = useMeasure(contentRef)

  return (
    <RepsypsSplashWrapper styles={{ size: width / 60 }}>
      <RepsypsBg style={{ background: `url('${bg}') `, backgroundSize: 'cover' }} />

      <Inner inRef={contentRef}>
        {width > 0 && (
          <>
            <Content>
              <AspectWrapper aspect={16 / 9}>
                <AspectInner>
                  <iframe
                    width="100%"
                    height="100%"
                    title={'a'}
                    src="https://player.vimeo.com/video/418592587?color=ffffff"
                    allow="autoplay; fullscreen"
                    style={{
                      border: 'none',
                      transform: 'scale(1.0167)',
                    }}
                  />
                  <GithubCorner />
                </AspectInner>
              </AspectWrapper>
            </Content>
            <Actions>
              <Download />
              <Action onClick={() => go('/repsyps/intro')}>
                <Icon name="repsypsf" data-fill style={{ height: '100%' }} />
                <ActionV>
                  <ActionTitle>Tutorials and Examples</ActionTitle>
                  <ActionDesc>explaining the thing...</ActionDesc>
                </ActionV>
              </Action>
            </Actions>
          </>
        )}
      </Inner>
    </RepsypsSplashWrapper>
  )
})

export default RepsypsSplash
