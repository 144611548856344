import React from 'react'

import Markdown from '../../markdown'
import * as Types from '../../types'

const article: Types.Article = {
  title: 'REPSYPS - Realtime Synced Musical Playback',
  url: 'repsyps',
  date: new Date('April 22 2020').getTime(),
  feedContent: (
    <Markdown>{`
      ![](media/2020-02-10-Repsyps/screencap.png)

      **[REPSYPS](/repsyps)**: realtime playback and synchronization of periodic signals... or music..
      
      ## Motivation

      For quite some time, since I first heard [this](https://youtu.be/9y6TUAngjUU?t=2223), I've been fascinated by the work of djs both as a means of creative expression and as a way to present new music.  Though I don't claim to be a part of this community, I've casually made mixes for personal consumption and friends for the better part of a decade. Since it's what I had access to, I'd create these mixes with basic nonlinear audio editing software. 
      
      *REPSYPS comes as an extension and automation of this experience*, and as such, takes a pretty strange angle when solving some of the same problems as other dj software. Rather than using the metaphor of two turntables, repsyps acts more like a mixer, sidestepping the skill and the art of turntablism. Instead, the focus is on mixing and selecting different parts of songs and [playing the console](https://www.youtube.com/watch?v=mMGTh0As2R4) like a dub sound engineer with cue points. 

      ## Features

      Fundamentally, repsyps involves dividing songs into many chunks (measures, beats, whatever you want) and playing them back in time with each other. Features include:
      
       - Realtime windowed resampling for large numbers of tracks
       - Automatic beat detection
       - Vocal separation, thanks to [spleeter](https://github.com/deezer/spleeter)
       - Pitch-preserving timestretch
       - Jumping between parts of a song, while staying on beat
       - Customizable cues points
       - MIDI controller support for cues and faders
       - Built in recording utilities
       - Prelistening, or cueing on a separate audio interface 
       - Saving and restoring reusable scenes

      ## Implementation

      Repsyps' ui is built in electron with react/redux. The realtime audio processing is written in c/c++ using the node addon api. Interestingly, the native backend is also controlled declaratively through redux.

      Check out the [demo and tutorials](/repsyps), or find it on [github](https://github.com/satchelspencer/repsyps).

`}</Markdown>
  ),
  tags: ['audio', 'looping'],
  //loadContent: () => import('./content'),
}

export default article
