import React from 'react'

import Markdown from '../markdown'
import * as Types from '../types'
import { COMMAND } from './splash'

const article: Types.Article = {
  title: '7. Building Your Library',
  shortTitle: '7. Library Building',
  url: 'library',
  feedContent: (
    <Markdown>{`
  <video remote="https://player.vimeo.com/video/417897590"/>
  
  **Goal:** *Create a reusable library of scenes, tracks and control presets.*

  ## Scene Reuse
  Fundamentally, making a good library in repsyps comes down to setting up your scenes and tracks in such a way that you can reuse them in a consistent way. There's lots of ways to do this, and it's purposefully left open to your personal preference. Here's a few broad guidelines:

   - Always include some way of mixing out of the previous scene in any scene you save. The simplest example is the \`A-B Scene\` control preset. You aren't limited to that preset though. Maybe you want two faders for the transition instead of one.
   - Don't assume that any two scenes will always follow one another. Leave yourself open to making decisions on the fly.
   - Setup cues for entering and exiting a track. It's super convenient to be able to just start a loop going for the track you're introducing and not worry about its timing during the transition. Add intro and outro cues to your tracks.

  ## Saving Scenes
  Individual scenes, groups of scenes, and their controls can be saved in \`.syp\` files. Syp files can be loaded on the fly as you need them. You can save all of the scenes in you rcurrent project with \`file > save project\` or if you'd like to only export the current scene use \`file > export scene\`. When you import a \`.syp\` file into a project, all of its scenes will get added to the end of your track list.

  ## Resuing Tracks Only
  Some workflows may involve setting up big control presets for many tracks and their parameters in a scene, then importing individual tracks into that scene as needed. Use \`scene > import tracks to scene\` or \`${COMMAND} shift I\` to do this. This is the most flexible way of mixing tracks, leaving the most up to in the moment decisions, and is definitely a viable approach. Assuming you have a midi controller with enough faders...
  
  ## Reusing Controls
  It's worth mentioning here that controls and control presets are intended to be reused across scenes. Notice how when you add a control to a track parameter in the scene it references the track by *number* not by name. Controls correspond to "Track 1" no matter what track 1 is. So be aware that when you reorder tracks, the controls applied to them will change. Referencing tracks by number means you can set up control presets that don't care what specific tracks are in their scene.

  ## Missing Media
  When repsyps saves a scene or project, It saves links to where on your computer its tracks' audio files are located. If you move the project or the track relative to one another, repsyps won't be able to find the audio. When you import a scene with missing tracks you'll be prompted with scary red tracks and the missing media finder (\`file > find missing media\`). When you click on one of the missing tracks you'll be prompted to find the new location of that track. If possible, repsyps will find the locations of the other tracks based on the first one.

  To alleviate this issues, you can save \`.syp\` files with their media alongside. Use \`file > export project\` and repsyps will create a folder with the \`.syp\` file and all of the audio files it needs. This is also useful for sharing projects with other people.
`}</Markdown>
  ),
  tags: [],
  contentPrompt: 'written version...',
  //loadContent: () => import('./content'),
}

export default article
