import React from 'react'
import ReactDOM from 'react-dom'

import './styles.css'
import { RouteContextProvider } from './router'
import App from './app'

if ('scrollRestoration' in window.history) window.history.scrollRestoration = 'manual'

ReactDOM.render(
  <RouteContextProvider>
    <App />
  </RouteContextProvider>,
  document.getElementById('root')
)
