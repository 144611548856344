import React, { useState } from 'react'
import ctyled, { inline, getPositions } from 'ctyled'

import Example from './example'
import Slider from './slider'

const DemoWrapper = ctyled.div.styles({
  gutter: 1,
  column: true,
}).extendSheet`
  margin-top:${({ size }) => size}px;
`

const Controls = ctyled.div.styles({
  bg: true,
  color: c => c.nudge(0.1).contrast(-0.1),
  rounded: true,
  padd: 2,
  column: true,
  gutter: 1,
})

const ControlWrapper = ctyled.div.styles({
  gutter: 2,
  align: 'center',
  width: '100%',
})

const SliderWrapper = ctyled.div.styles({
  flex: 1,
})

const ControlName = ctyled.div.styles({}).extendSheet`
  font-weight:bold;
`

const ColorGrad = ctyled.div
  .attrs<{stops: any}>({
    stops: [],
  })
  .styles({
    rounded: true,
    border: true,
    height: 1.5,
    flex: '1',
    borderColor: c => c.contrast(-0.1),
  }).extend`
  background:linear-gradient(to right, ${(_, { stops }) =>
    getPositions(stops)
      .map(stop => stop.color + ' ' + stop.pos * 100 + '%')
      .join(',')});
`

const ColorSelection = ctyled.div.class(inline).styles({
  align: 'center',
  justify: 'center',
  border: true,
  bg: true,
  rounded: true,
  size: s => s * 0.8,
}).extendSheet`
  top:-50%;
  position:absolute;
  width:${({ size }) => size * 2}px;
  height:${({ size }) => size * 2}px;
  margin-left:-${({ size }) => size}px;
  transition:0.4s all;


  :after, :before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  :after {
    border-color: transparent;
    border-top-color: ${({ color }) => color.bg};
    border-width: 5px;
    margin-left: -5px;
  }
  :before {
    border-color: transparent;
    border-top-color: ${({ borderColor }) => borderColor.bq};
    border-width: 6px;
    margin-left: -6px;
  }
`

const Prompt = ctyled.div.styles({ size: s => s * 1.2, width: '100%', justify: 'center' })
  .extendSheet`
  font-style:italic;
`

const ExampleWrapper = ctyled.div

export default function ColorDemo() {
  const [contrast, setContrast] = useState(0.5),
    [lum, setLum] = useState(0.8),
    [inverted, setInverted] = useState(false),
    stops = ['black', 'white']

  const range = contrast * 2
  const normLum = (lum + 1) / 2
  const base = (2 - range) * normLum
  let fgLum = (-1 + base + 1) / 2
  let bgLum = (-1 + base + range + 1) / 2

  if (inverted) {
    let t = fgLum
    fgLum = bgLum
    bgLum = t
  }

  return (
    <DemoWrapper>
      <ExampleWrapper
        styles={{
          color: c => {
            c = c.absLum(lum).absContrast(contrast)
            if (inverted) c = c.invert()
            return c
          },
        }}
      >
        <Example />
      </ExampleWrapper>
      <Prompt>Play with me! This interface is interactive.</Prompt>
      <Controls>
        <ControlWrapper>
          <ColorGrad stops={stops}>
            <ColorSelection style={{ left: bgLum * 100 + '%' }}>BG</ColorSelection>
            <ColorSelection style={{ left: fgLum * 100 + '%' }}>FG</ColorSelection>
          </ColorGrad>
          <ControlName
            style={{ cursor: 'pointer' }}
            onClick={() => setInverted(!inverted)}
          >
            Invert
            <input type="checkbox" checked={inverted} onChange={() => {}} />
          </ControlName>
        </ControlWrapper>
        <ControlWrapper>
          <ControlName>Luminance</ControlName>
          <SliderWrapper>
            <Slider onChange={setLum} value={lum} />
          </SliderWrapper>
        </ControlWrapper>
        <ControlWrapper>
          <ControlName>Contrast</ControlName>
          <SliderWrapper>
            <Slider onChange={setContrast} value={contrast} />
          </SliderWrapper>
        </ControlWrapper>
      </Controls>
    </DemoWrapper>
  )
}
