import React, { memo, useRef } from 'react'
import ctyled, { active } from 'ctyled'

import useMeasure from './measure'

export const MainHead = ctyled.div.class(active).styles({
  size: (s) => s * 2,
}).extendSheet`
  font-weight:bold;
`

const SIDEBAR_WIDTH = 10,
  FULL_WIDTH = 60

export const FeedWrapper = ctyled.div.styles({
  bg: true,
  align: 'center',
  column: true,
  padd: 1,
}).extendSheet`
position:absolute;
top:0;
left:0;
width:100%;
`

export const FeedCenter = ctyled.div.styles({
  align: 'flex-start',
  gutter: 2,
}).extendSheet`
  margin-top:${({ size }) => size * 6}px;
  width:${FULL_WIDTH * 14}px;
  @media (max-width:${FULL_WIDTH * 14}px){
    width:100%;
    margin-top:0;
  }
`

export const FeedBody = ctyled.div.styles({
  flex: 1,
  column: true,
  gutter: 2,
}).extendSheet`
 margin-right:${({ size }) => size * (SIDEBAR_WIDTH + 1)}px;
 padding-bottom:100%;
`

export const FeedBar = ctyled.div.styles({}).extendSheet`
  position:fixed;
  top:${({ size }) => size * 6}px;
  width:${({ size }) => FULL_WIDTH * size}px;
  @media (max-width:${({ size }) => size * FULL_WIDTH}px){
    width:100%;
  }
`

export const FeedBarInner = ctyled.div.styles({
  width: SIDEBAR_WIDTH,
  column: true,
  align: 'center',
  gutter: 1,
}).extendSheet`
  position:absolute;
  right:0;
  top:0;
`

export const FeedBarHead = ctyled.div.class(active).styles({
  column: true,
  gutter: 1,
  align: 'center',
})

export const FeedItem = ctyled.div
  .attrs<{ hidden?: boolean }>({ hidden: true })
  .styles({
    color: (c, p) => (p && p.hidden ? undefined : c.contrast(0.2)),
    bg: (_, p) => !(p && p.hidden),
    padd: (_, p) => (!(p && p.hidden) ? 2 : 0),
    rounded: 1,
    column: true,
    gutter: 0,
    border: (_, p) => !(p && p.hidden),
    borderColor: (c) => c.contrast(-0.3),
  }).extendSheet`
`

export const FeedItemPlaceHolder = FeedItem.styles({
  color: (c) => c.contrast(-0.4),
})

export interface FeedSeparatorPorps {
  height?: number
  children: any
  size?: number
}

const YearBorder = ctyled.div.styles({
    gutter: 2,
    align: 'center',
    color: (c) => c.contrast(-0.1),
  }),
  YearBorderLine = ctyled.div.styles({ flex: 1 }).extendSheet`border:1px solid ${({
    color,
  }) => color.bq};`,
  YearBorderName = ctyled.div.styles({
    size: (s) => s * 2,
    color: (c) => c.contrast(-0.1),
    align: 'center',
  })

export const FeedSeparator = memo((props: FeedSeparatorPorps) => {
  return (
    <YearBorder>
      <YearBorderLine />
      <YearBorderName
        styles={{
          height: props.height,
          size: (s) => s * (props.size === undefined ? 1 : props.size),
        }}
      >
        {props.children}
      </YearBorderName>
      <YearBorderLine />
    </YearBorder>
  )
})

export interface FeedLayoutProps {
  body: () => any
  sidebar: () => any
}

const FeedLayout = memo((props: FeedLayoutProps) => {
  const contentRef = useRef<any>(null),
    { width } = useMeasure(contentRef)

  return (
    <FeedWrapper>
      <FeedCenter inRef={contentRef} styles={{ size: width / 60 }}>
        {width > 0 && <FeedBody>{props.body()}</FeedBody>}
      </FeedCenter>
      <FeedBar styles={{ size: width / 60 }}>
        {width > 0 && <FeedBarInner>{props.sidebar()}</FeedBarInner>}
      </FeedBar>
    </FeedWrapper>
  )
})

export default FeedLayout
