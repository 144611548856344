import React from 'react'

import Markdown from '../../markdown'
import * as Types from '../../types'

const article: Types.Article = {
  title: 'TTRL - Live Visualization',
  date: new Date('Oct 10 2019').getTime(),
  url: 'ttrl',
  feedContent: (
    <Markdown>{`
      <video remote="https://player.vimeo.com/video/418648265"/>

      Created for [James Allister Sprang](https://jamesallistersprang.com/)'s performance *Turning Towards a Radical Listening*. 
      
      This visualization uses google's speech to text ai, and a custom layout engine to display in real time what the ai thinks it hears during the live performance. I created a templating system so that the artist can design arbitrary [concrete poetry](https://en.wikipedia.org/wiki/Concrete_poetry) text layouts ahead of time that the system will fill in with the text generated during the show.

      Performed at the [the kitchen nyc](https://thekitchen.org/blog/105) in October 2019.

      Source [on Github](https://github.com/satchelspencer/ttrl).
`}</Markdown>
  ),
  tags: ['audio', 'looping'],
}

export default article
