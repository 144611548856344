import React from 'react'

import Markdown from '../../markdown'
import * as Types from '../../types'

const article: Types.Article = {
  title: 'Foodwaste Visualization',
  date: new Date('May 01 2017').getTime(),
  url: 'foodwaste',
  feedContent: (
    <Markdown>{`
    ![(1.8)](media/2017-5-01-Foodwaste/food.jpg)
    *Adaptive clustering visualization of food waste sources in NYC.*

    As the public outreach portion of a study funded by the [National Resources Defense Council](https://www.nrdc.org/), this web app visualizes anonymous data about the sources of food waste in select cities in the United States. The map utilizes a custom made clustering algorithm, partly based on quadtrees and zip codes, to adaptively recompute the visualization. This could not be feasibly precomputed since users could apply arbitrary filters to the raw data, updating the map in real time. Given 100k+ data points, this was a significant optimization problem in the browser.

    The distribution requirements for the project also included it being completely static, with no server side component. This posed many interesting challenges for data anonymization, compression and live processing speed. A mirror of the original demo is available [here](/media/2017-5-01-Foodwaste/index.html)

`}</Markdown>
  ),
  tags: [],
}

export default article
