import React from 'react'

import Markdown from '../../markdown'
import * as Types from '../../types'

import ColorDemo from './demo'

const article: Types.Article = {
  title: 'Relative Color in Interface Design',
  url: 'ctyled',
  date: new Date('July 2 2018').getTime(),
  feedContent: (
    <>
      <ColorDemo />
      <br/>
      <Markdown>{`
        Of the time I've spent making user interfaces, a shockingly large portion of it has been spent agonizing over the subtle color differences between the elements of the interface. Often, making a change to part of the ui means individually tweaking the styles for all of its children so as to match the parent. CSS templating, computed styles, etc *do not completely address this*.

        My proposal is to define all of the colors *relative to each other*. "the text should have X contrast against the background.",  "this whole pane should turn red", "this button should be inverted". What this gets you can be seen above.

        A working implementation in react can be found on [my github](https://github.com/satchelspencer/ctyled).
      `}</Markdown>
    </>
  ),
  loadContent: () => import('./content'),
  tags: [],
}

export default article
