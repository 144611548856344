import React, { memo } from 'react'
import ctyled from 'ctyled'
import { sortBy } from 'lodash'

import * as Types from '../types'
import { useRouter } from '../router'

import Icon from '../icons/icon'

import Article from '../article'
import Feed from '../feed'
import FeedLayout, {
  FeedItem,
  FeedItemPlaceHolder,
  FeedBarHead,
  MainHead,
  FeedSeparator,
} from '../feed-layout'

import { Download, DlLink } from './splash'
import TimeGrid from './1_timegrid'
import Scenes from './2_scenes'
import Mashup from './3_mashup'
import Controls from './4_controls'
import Midi from './5_midi'
import Recording from './6_record'
import Library from './7_library'
import Outputs from './8_outputs'
import TimeGrid2 from './9_timegrid2'
import Controls2 from './10_controls2'
import Cues from './11_cues2'
import Nosync from './12_nosync'

const Articles = {
  0: {
    title: 'Download',
    shortTitle: 'Download',
    order: 0,
    url: 'intro',
    rawContent: (
      <>
        <Download wide />
        <div style={{height:12}}/>
        <FeedSeparator height={2} size={1}>
          Source Code & Help Available on&nbsp;
          <DlLink
            onClick={() =>
              (window.location.href = 'https://github.com/satchelspencer/repsyps')
            }
          >
            Github
          </DlLink>
        </FeedSeparator>
      </>
    ),
    tags: [],
  },
  1: TimeGrid,
  2: Scenes,
  3: Mashup,
  4: Controls,
  5: Midi,
  6: Recording,
  7: Library,
  8: Outputs,
  9: TimeGrid2,
  10: Controls2,
  11: Cues,
  12: Nosync,
}

const Toc = ctyled.div.styles({
  column: true,
  gutter: 1,
}).extendSheet`
  padding-top:${({ size }) => size}px;
  padding-left:${({ size }) => size * 1.25}px;
`

const TocItem = ctyled.div.styles({
  size: (s) => s * 1,
}).extendSheet`
  cursor:pointer;
  &:hover{
    text-decoration:underline;
  }
`

const RepsypsWrapper = ctyled.div.styles({
  color: (c) => c.absLum(0.9).contrast(0.1),
}).extend`
  position:absolute;
  top:0;
  left:0;
  right:0;
  font-family:'PT Sans', sans-serif;
`

export interface ArticleRenderProps {
  article: Types.Article
  isVisible: boolean
  aid: string
  aindex: number
  ref: (ref: any) => void
  height: number
  selected: boolean
}

const RepsypsFeed = memo(() => {
  const { go, path } = useRouter(),
    articles: Types.Articles = Articles,
    sorted = sortBy(Object.keys(articles), (n) => parseInt(n))

  return (
    <RepsypsWrapper>
      <FeedLayout
        sidebar={() => {
          return (
            <>
              <FeedBarHead styles={{ gutter: 0.5 }} onClick={() => go('/repsyps')}>
                <Icon
                  name="repsypsf"
                  data-fill
                  style={{ width: '80%', opacity: 0.7 }}
                  styles={{ color: (c) => c.as(['red', 'red']) }}
                />
                <MainHead
                  style={{
                    opacity: 0.7,
                    fontFamily: 'sans-serif',
                    fontWeight: 400,
                    letterSpacing: 3,
                  }}
                  styles={{ color: (c) => c.as(['red', 'red']) }}
                >
                  repsyps
                </MainHead>
              </FeedBarHead>
              <Toc>
                {sorted.map(
                  (id) =>
                    !!articles[id].shortTitle && (
                      <TocItem
                        style={{
                          fontWeight:
                            '/repsyps/' + articles[id].url === path ? 'bold' : 'normal',
                        }}
                        key={id}
                        onClick={() => go('/repsyps/' + articles[id].url)}
                      >
                        {articles[id].shortTitle || articles[id].title}
                      </TocItem>
                    )
                )}
              </Toc>
            </>
          )
        }}
        body={() => {
          return (
            <>
              <Feed articles={articles} sorted={sorted} route="/repsyps">
                {({ article, isVisible, aindex, aid, ref, selected, height }) => {
                  return isVisible ? (
                    <FeedItem
                      styles={{ color: (c) => c.contrast(-0.1).nudge(0.05) }}
                      key={aindex}
                      inRef={ref}
                      hidden={!!article.rawContent}
                    >
                      <Article
                        route="/repsyps"
                        selected={selected}
                        article={article}
                        articleId={aid}
                      />
                    </FeedItem>
                  ) : (
                    <FeedItemPlaceHolder style={{ height }} key={aid} inRef={ref} />
                  )
                }}
              </Feed>
              <FeedSeparator height={4}>
                <Icon name="repsyps" style={{ height: 20 }} />
              </FeedSeparator>
            </>
          )
        }}
      />
    </RepsypsWrapper>
  )
})

export default RepsypsFeed
