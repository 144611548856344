import { default as cms } from './2014-12-04-Cms'
import { default as proj } from './2015-7-12-Projection'
import { default as tauto } from './2016-6-20-Tautomata'
import { default as matrix } from './2017-2-12-Matrix'
import { default as food } from './2017-5-01-Foodwaste'
import { default as bifsi } from './2018-06-08-Bifsi'
import { default as ctyledArticle } from './2018-07-02-Ctyled'
import { default as vauto } from './2019-04-16-Vautomata'
import { default as ttrl } from './2019-10-9-TTRL'
import { default as dct } from './2019-11-24-DCTGAN'
import { default as rhythmogram } from './2020-01-20-Rhythmogram'
import { default as repsyps } from './2020-02-10-Repsyps'

import React, { memo, useCallback, Fragment } from 'react'
import ctyled, { active, inline } from 'ctyled'
import { sortBy, groupBy } from 'lodash'
import format from 'dateformat'

import * as Types from '../types'
import { useRouter } from '../router'

import Markdown from '../markdown'
import Icon from '../icons/icon'

import Article from '../article'
import Feed from '../feed'
import FeedLayout, {
  FeedItem,
  FeedItemPlaceHolder,
  FeedBarHead,
  MainHead,
  FeedSeparator,
} from '../feed-layout'

const Articles = {
  cms,
  proj,
  tauto,
  matrix,
  food,
  bifsi,
  ctyledArticle,
  vauto,
  ttrl,
  dct,
  rhythmogram,
  repsyps,
}

const FeedYear = ctyled.div
  .class(inline)
  .class(active)
  .styles({
    hover: 0.5,
    padd: 2,
    bg: true,
    color: (c) => c.nudge(0.2),
  })

const Faketicles: Types.Articles = {},
  now = new Date().getTime()
for (let i = 0; i < 20; i++) {
  Faketicles[i + 'a'] = {
    title: 'Faketicle ' + i,
    url: 'ft' + i,
    date: now - i * 3600 * 24 * 60 * 1000,
    feedContent: (
      <Markdown>
        {new Array(Math.floor(Math.sin(i) * 7 + 7))
          .fill(0)
          .map((a) => '### huh \n')
          .join('')}
      </Markdown>
    ),
    tags: [],
  }
}

export interface ArticleRenderProps {
  article: Types.Article
  isVisible: boolean
  aid: string
  aindex: number
  ref: (ref: any) => void
  height: number
  selected: boolean
}

const ArticlesFeed = memo(() => {
  const { go } = useRouter(),
    articles: Types.Articles = Articles, //{ ...Faketicles, ...Articles },
    years = Object.keys(
      groupBy(Object.keys(articles), (id) => format(articles[id].date, 'yyyy'))
    )
      .sort()
      .reverse(),
    sorted = sortBy(Object.keys(articles), (id) => (articles[id].date || 0) * -1)

  const goYear = useCallback(
    (year: string) => {
      const articleId = sorted.find((aid) => format(articles[aid].date, 'yyyy') === year)
      if (articleId) go('/feed/' + articles[articleId].url)
    },
    [sorted, articles, go]
  )

  return (
    <FeedLayout
      sidebar={() => {
        return (
          <>
            <FeedBarHead onClick={() => go('/')}>
              <Icon name="starLine" data-fill style={{ width: '80%' }} />
              <MainHead>ELLDEV</MainHead>
            </FeedBarHead>

            {years.map((year) => (
              <FeedYear key={year} onClick={() => goYear(year)}>
                {year}
              </FeedYear>
            ))}
          </>
        )
      }}
      body={() => {
        return (
          <>
            <Feed articles={articles} sorted={sorted} route="/feed">
              {({ article, isVisible, aindex, aid, ref, selected, height }) => {
                const currentYear = format(article.date, 'yyyy'),
                  startsYear =
                    sorted[aindex - 1] &&
                    format(articles[sorted[aindex - 1]].date, 'yyyy') !== currentYear
                return (
                  <Fragment key={aindex}>
                    {startsYear && <FeedSeparator>{currentYear}</FeedSeparator>}
                    {isVisible ? (
                      <FeedItem hidden={!!article.rawContent} inRef={ref}>
                        <Article
                          route="/feed"
                          selected={selected}
                          article={article}
                          articleId={aid}
                        />
                      </FeedItem>
                    ) : (
                      <FeedItemPlaceHolder style={{ height }} key={aid} inRef={ref} />
                    )}
                  </Fragment>
                )
              }}
            </Feed>
            <FeedSeparator height={4}>
              {<Icon name="star" style={{ height: 40 }} />}
            </FeedSeparator>
          </>
        )
      }}
    />
  )
})

export default ArticlesFeed
