import React from 'react'

import Markdown from '../markdown'
import * as Types from '../types'

const article: Types.Article = {
  title: '2. Scenes and Tracks',
  shortTitle: '2. Scenes & Tracks',
  url: 'scenes',
  feedContent: (
    <Markdown>{`
  <video remote="https://player.vimeo.com/video/417876988"/>
  
  **Goal:** *Use scenes and control presets for simple transitions between tracks.*

  ## Scenes
   Scenes are containers for single tracks or groups of tracks that can be saved and loaded on the fly. Let's look at their simplest use case for transitioning through a series of tracks. If you haven't already, set up a couple tracks to play with as described in the [previous tutorial](#/repsyps/grid)

   You may have already noticed the header at the top of the tracklist with a big **1**. This is the scene header marking the top of the first scene. To start with, repsyps only has one scene, but we can add another with \`scene > new scene after\` or \`command N\`. 
   
   Now we have an empty scene at the bottom of our tracklist labeled **2**. You can move tracks between scenes using their handles at the far left of the track. Move the second track we set up in the previous tutorial down into scene **2**. Notice how the scene header for **2** is selected and underlined indicating we are in scene **2**. You can also see this in the *scene indicator* below the tracklist showing that we have selected scene 2 track 1 of 1. You can use the arrows on far sides of the scene indicator to step between scenes, or click on the scene headers to select them.

    - Click on the **1** scene header so select scene **1**. Notice how the track under scene **2** loses its waveform. This indicates that the scene is no longer loaded.
    - Repsyps only keeps your current scene and *one previous scene* loaded at a time so as to conserve memory and cpu resources.
    - Move to scene **2**, and the scene **2** track will load, leaving the scene **1** tracks loaded so we can transition out of them.

  *But how do we mix or transition between scenes?*

  ## Scene Controls
  At this point, we can mix our tracks just as before by selecting each track and moving its volume slider. This is terrible since you have to select each track individually, and change their volume one at a time. Scenes have a much nicer way to handle this: *the control grid*. Let's use the control grid to transition between our two scenes:

  - Repsyps comes with a few *control presets* listed on the right side of the control grid. These cover some very basic use cases, but you *can* make your own as described in the [custom controls tutorial](/repsyps/controls).
   - Jump to scene **2** by clicking on its header or by selecting one of it's tracks.
   - Select the "A-B Scene" preset on the right, then click \`Apply\`.
   - Now you have a single knob labeled "Prev Scene, Current Scene". This will mute the current scene when you first load it, then turning that knob down will crossfade between the previous scene and the current scene. *Click and drag up and down, or left and right to move the knob*.
   - Start the track in scene **1** playing, then move down to scene **2** and start playing that track.
   - You won't hear scene **2** until you fade it in with the control knob.

  **Yes!** *repsyps has a way to cue tracks or pre-listen without playing them in the main output.  
  See the [outputs tutorial](/repsyps/outputs)*.

  ## Cue Points
  Often you won't want to start exactly at the start of a track, or you may want to save certain sections of a track to jump back to later. To help with this, repsyps allows you to add *cues* to any track. Cues can be found for each track in the *track details pane* under \`playback cues\`. When you want to save the currently playing part of a track for future use, hit \`add cue\` or \`command + enter\`. The cue start and end points will appear on the waveform in magenta, and the cue will be added to the cue list. You can jump to the cue by clicking it in the cues list, or by stepping through your cues using the previous and next cue buttons. More advanced cue functionality is discussed in the [cues tutorial](/repsyps/cues)

  ## Playing a Simple Tracklist
  Now that we can do a basic transition between scenes, as an exercise, let's make a third scene and import a new track. Once we set up the grid for this track, Apply the "A-B Scene" preset to this scene, and we're ready to play though a few tracks.

   - Move back to scene **1**. Start that track playing (or start its first cue).
   - Move down to scene **2**. Start the scene **2** track playing (you won't hear it at first since it is muted by the control).
   - Fade in scene two using the one knob. (While you are in scene **2** you can still start and stop and mess with scene **1** without jumping back to scene **1**.)
   - Once scene **2** is fully faded in, jump to scene **3**. Now scene **1** is unloaded but scene **2** is still playing. As before fade in scene **3** as you see fit.
   - You can reset your current scene back to its starting point with \`scene > reset scene\` or \`command R\`. This is useful when practicing transitions. 

  **Note** *You'll probably want to save your scenes individually, so you can use them on the fly. This is discussed in the [library building tutorial](/repsyps/library)*
  
`}</Markdown>
  ),
  tags: [],
  contentPrompt: 'written version...',
  //loadContent: () => import('./content'),
}

export default article
