import React, { memo, useState, useEffect, useCallback, useRef, Fragment } from 'react'
import { throttle, findIndex } from 'lodash'

import * as Types from './types'
import { useRouter } from './router'

const PLACEHOLDER_HEIGHT = 300

export interface ArticleRenderProps {
  article: Types.Article
  isVisible: boolean
  aid: string
  aindex: number
  ref: (ref: any) => void
  height: number
  selected: boolean
}

export interface FeedProps {
  articles: Types.Articles
  sorted: string[]
  children: (props: ArticleRenderProps) => any
  route: string
}

const Feed = memo((props: FeedProps) => {
  const { path, replace } = useRouter(),
    selected = path.replace(props.route, '').substr(1),
    selIndex = findIndex(props.sorted, (aid) => props.articles[aid].url === selected),
    click = useRef(0)

  useEffect(() => {
    if (selIndex === -1) {
      replace(props.route + '/' + props.articles[props.sorted[0]].url)
    }
  }, [selIndex, props.sorted, props.route, props.articles, replace])

  const setScrollTop = useState(0)[1],
    [windowHeight, setWinHeight] = useState(window.innerHeight),
    [isFirst, setIsFirst] = useState(true),
    handleScroll = useCallback(
      throttle(
        () => {
          setScrollTop(window.scrollY)
          if (!divs.current[selIndex]) return
          const top = divs.current[selIndex].offsetTop || PLACEHOLDER_HEIGHT * selIndex,
            height = divs.current[selIndex].offsetHeight || PLACEHOLDER_HEIGHT,
            isVisible =
              top + height > window.scrollY && top < window.scrollY + windowHeight
          if (window.scrollY === 0) {
            replace(props.articles[props.sorted[0]].url)
          } else if (!isVisible) {
            const newSelectedIndex = Math.min(
                Math.max(top > window.scrollY ? selIndex - 1 : selIndex + 1, 0),
                props.sorted.length - 1
              ),
              newSelected = props.sorted[newSelectedIndex]
            replace(props.route + '/' +props.articles[newSelected].url)
          }
        },
        50,
        { leading: false }
      ),
      [selected, props.sorted]
    ),
    handleResize = useCallback(
      throttle(() => setWinHeight(window.innerHeight), 100, { leading: false }),
      []
    ),
    handleClick = useCallback(() => (click.current = new Date().getTime()), [])

  const divs = useRef<any[]>(props.sorted.map((a) => PLACEHOLDER_HEIGHT)),
    realHeights = useRef<number[]>([])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)
    window.addEventListener('click', handleClick)
    window.addEventListener('popstate', handleClick)
    window.addEventListener('history', handleClick)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('click', handleClick)
      window.removeEventListener('popstate', handleClick)
      window.removeEventListener('history', handleClick)
    }
  }, [handleScroll, handleResize, handleClick])

  useEffect(() => {
    const offset = divs.current[selIndex === -1 ? 0 : selIndex].offsetTop

    if (
      isFirst ||
      (new Date().getTime() - click.current < 100 &&
        (offset < window.scrollY || offset > window.scrollY + window.innerHeight / 2))
    ) {
      console.log('scroll')
      window.scrollTo({ top: offset })
    }

    setTimeout(() => setIsFirst(false))
  }, [selIndex, isFirst])

  return (
    <Fragment>
      {props.sorted.map((aid, aindex) => {
        const article = props.articles[aid],
          top = divs.current[aindex].offsetTop || PLACEHOLDER_HEIGHT * aindex,
          height = divs.current[aindex].offsetHeight || PLACEHOLDER_HEIGHT,
          isVisible =
            !isFirst &&
            top + height > window.scrollY &&
            top < window.scrollY + windowHeight

        return props.children({
          article,
          isVisible,
          ref: (ref) => {
            divs.current[aindex] = ref
            if (ref) realHeights.current[aindex] = ref.offsetHeight
          },
          aid,
          aindex,
          height: realHeights.current[aindex] || PLACEHOLDER_HEIGHT,
          selected: selected === article.url,
        })
      })}
    </Fragment>
  )
})

export default Feed
