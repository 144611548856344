import React from 'react'

import Markdown from '../markdown'
import * as Types from '../types'
import { COMMAND } from './splash'

const article: Types.Article = {
  title: '1. The Time Grid',
  shortTitle: '1. The Time Grid',
  url: 'grid',
  feedContent: (
    <Markdown>{`
  <video remote="https://player.vimeo.com/video/417878951"/>
  
  **Goal:** *Play two tracks in sync with each other.*

  ## Orientation
  To start with, let's take note of the different sections of the user interface.

  1. **Global Controls** *in the header of the ui* are used to control global playback, volume, speed etc.
  2. **The Tracklist** *in the right side top pane* contains all the tracks you'll play. As you work your way between tracks, you'll typically move down the list.
  3. **Track Details** *in the left sidebar* show track properties for whichever track you've selected.
  4. **The Control Grid** *in the right side bottom pane* is where you can add custom shortcuts for controlling the properties of your tracks. [More about this later](/repsyps/controls).

  ## Adding a Track
  Now let's import a track to the tracklist. Use \`file > import\` or \`${COMMAND} I\` or drag your track onto the list. Repsyps supports many, many [audio formats](https://en.wikipedia.org/wiki/Libavcodec#Implemented_audio_codecs).  
  
  Once the track is loaded, you'll see its waveform and name in the track list. You can move and zoom around the track using the two finger scroll and pinch, or using the mousewheel and \`${COMMAND}\` or \`shift\`. Clicking on the track will select where the playback starts from. \`spacebar\` starts and stops the track. Once you pause, playback will reset to the same playback start position (indicated by the thin red bar) so you can easily listen to the same section repeatedly. If you \`click and drag\` or \`shift click\`, you can select a looping region of the track. You can also drag the edges of your playback region once it's selected.

  ## The Time Grid
  Before repsyps can sync this track with other tracks we have to create the track's *time grid*. This involves selecting a single measure or beat that loops seamlessly, and letting repsyps figure it out the rest of the song. If you're already familiar with this sort of concept you can approach this as you see fit, but if not here's a simple approach:
  - Typical rhythms in popular music have some alternation between low and high pitched percussion. Think "*boom*" and "*bap*".
  - Often times the "*bap*" will be a [snare drum](https://youtu.be/VAfvL8v6lSg?t=30) or a [clap](https://www.youtube.com/watch?v=JW68goC4_es). These are convenient for marking the time grid since they often stand out clearly on a waveform, to your ears, and to [repsyps' analysis](https://github.com/satchelspencer/repsyps/blob/master/src/native/impdet.cc)
  - Play a region of a song with distinctive percussion.
  - Listen for the "*baps*" on the up beat, and try to see where they correspond to in the waveform. Repsyps will try to mark these for you with little red ticks on the waveform.
  - \`click and drag\` or \`shift click\` to select a part of the track starting at one snare *skipping over one* and ending on the next snare.
  - This should loop pretty nicely, and correspond to the 2 beat of one measure to the 2 beat of the next... assuming 4/4... probably 4/4 if you're reading this tbh.

  **P.S.** *Yes... you can and should mark your grid on the down beat if you want. I've found that the up beat is easier for many people to identify. You can mark your grid on every beat if you want and go sicko mode. It's your life*

  Now that we've got a seamless loop, go to the *track details* sidebar under *Time Grid* and click \`Infer\`. Repsyps will extrapolate out, marking the grid for the rest of the track indicated by vertical bars and numbers on the top of the track. If you're happy with it, go ahead and click \`Save\` under time divisions. If you're not, check out the [advanced time grid tutorial](/repsyps/adv-grid).

  ## Synchronized Playback
  Once a track has a time grid, repsyps will, by default, play it back in sync with the global tempo. The global tempo can be seen in the top right \`something something/m\` with a little spinning indicator metronome to let you know where you are in the global tempo. 

  When you click on a track with a time grid it will play and loop the measure that you clicked on. Clicking and dragging over multiple measures will select and loop multiple measures. Use the hotkey \`E\` to play forward though the rest of the track. Play around! Note that as you switch between measures, if you're half-way through one measure when you switch, you'll end up half-way through the new measure. This way you always stay on beat. You can cut around to different parts of a track like this, which is enjoyable but maybe not productive. If the time grid doesn't land on beats for some part of the song, look at the [advanced time grid tutorial](/repsyps/adv-grid) for potential issues.

  ## Two Tracks!
  Now let's repeat the process with another track. Import, select a loop, infer, save. Now you're ready to mix them. When you select \`click and drag\` and play \`spacebar\` parts of each track, they'll play on top of each other in sync. Use the \`up arrow\` and \`down arrow\`,  \`1\` and \`2\` or just click to change your track selection. You can use the \`track volume\` controls in the track details pane to fade each track in and out. This works for experimentation, but it isn't a particularly nice way to transition between tracks, or mix them. Better methods in the next tutorial...
`}</Markdown>
  ),
  tags: [],
  contentPrompt: 'written version...',
  //loadContent: () => import('./content'),
}

export default article
