import React from 'react'
import ReactMarkdown from 'react-markdown'

import ctyled from 'ctyled'

const isDev = process.env.NODE_ENV === 'development'

const MdWrapper = ctyled.div.styles({
  column: true,
  color: (c) => c.contrast(-0.1),
  size: (s) => s * 1.1,
  gutter: 1.3,
}).extendSheet`
  & p, & ul, & ol, & pre, & blockquote, & div {
    margin:${({ size }) => size / 4}px 0px;
    line-height:1.5em;
    position:relative;
  }

  & ul, & ol{
    margin:0px !important;
    padding-left:${({ size }) => size * 1.5}px;
  }

  & ul {
    list-style-type: circle;
  }

  & li:not(:last-child){
    margin-bottom:${({ size }) => size / 4}px;
  }

  & a {
    text-decoration:underline;
  }

  & blockquote{
    border-left:2px solid ${({ borderColor }) => borderColor.bq};
    padding-left:${({ size }) => size}px;
    margin-left:${({ size }) => size}px;
  }

  & pre{
    background:${({ color }) => color.nudge(0.05).bg};
    border-radius:${({ size }) => size / 4}px;
    padding:${({ size }) => size / 2}px;
  }

  & h1, & h2, & h3, & h4{
    font-weight:normal;
    margin-top:${({ size }) => size * 1}px !important;
  } 

  & code{
    background:${({ color }) => color.contrast(-0.25).bg};
    padding: 0px 4px;
    margin: 0px 0px;
    border-radius: 3px;
    font-size:${({ size }) => size * 0.8}px;
  }

  & img{
    width:100%;
    border-radius: 2px;
  }

  & iframe{
    width:100%;
    background:${({ color }) => color.contrast(-0.2).nudge(-0.02).bg};
    border:none;
  }
`

function removeSpaces(text: string): string {
  const lines = text.split('\n')
  if (lines.length < 2) return text
  else {
    const spacematch = lines[1].match(/^\s+/)
    const spacesCount = spacematch ? spacematch[0].length : 0
    return lines.map((line, i) => (!i ? line : line.substr(spacesCount))).join('\n')
  }
}

const LinkRender = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
)

export const AspectWrapper = ctyled.div.attrs({ aspect: 1 }).extend`
  width:100%;
  padding-top:${(_, { aspect }) => 100 / aspect}%;
`

export const AspectInner = ctyled.div.styles({
  color: (c) => c.contrast(-0.2).nudge(-0.02),
  bg: true,
  rounded: 1,
}).extend`
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  overflow:hidden;
`

const ImageInner = AspectInner.attrs({ src: '' }).extend`
  background:url('/${(_, { src }) => src}') ${({ color }) => color.bg};
  background-size:cover;
  background-position: center;
`

const ImageRender = ({ src, alt }) => {
  const aspectMatch = ((alt && alt.match(/\(([0-9.]+)\)/i)) || [])[1],
    aspect = aspectMatch ? parseFloat(aspectMatch) : 1.777
  return (
    <AspectWrapper aspect={aspect}>
      <ImageInner src={src} />
    </AspectWrapper>
  )
}

const ParaRender = (props: any) => <div {...props} />

const HTMLRender = ({ value }) => {
  if (value.indexOf('<video') === 0) {
    const remote = (value.match(/remote="([^"]+)"/i) || [])[1],
      local = (value.match(/local="([^"]+)"/i) || [])[1],
      aspect = parseFloat((value.match(/aspect="([^"]+)"/i) || [])[1] || '1.777'),
      gif = value.indexOf('asGif') !== -1

    return (
      <AspectWrapper aspect={aspect}>
        <AspectInner>
          {isDev && local ? (
            <video
              style={{ width: '100%', height: '100%' }}
              controls={!gif}
              autoPlay={gif}
              muted={gif}
              poster={'/media/' + local + '.jpg'}
              src={'/media/' + local + '.mp4'}
            />
          ) : remote.indexOf('vimeo.com') !== -1 ? (
            <iframe
              width="100%"
              height="100%"
              title={remote}
              src={remote + '?' + (gif ? 'autoplay=1' : '')}
              allow="autoplay; fullscreen"
            />
          ) : null}
        </AspectInner>
      </AspectWrapper>
    )
  } else if (value.indexOf('<gif') === 0) {
    const source = (value.match(/src="([^"]+)"/i) || [])[1],
      aspect = parseFloat((value.match(/aspect="([^"]+)"/i) || [])[1] || '1.777')

    return (
      <AspectWrapper aspect={aspect}>
        <AspectInner>
          <video
            style={{ width: '100%', height: '100%' }}
            controls={false}
            autoPlay
            muted
            loop
            poster={'/media/' + source + '.jpg'}
            src={'/media/' + source + '.mp4'}
          />
        </AspectInner>
      </AspectWrapper>
    )
  } else return null
}

interface TextProps {
  children: string
}

export default function Markdown(props: TextProps) {
  return (
    <MdWrapper>
      <ReactMarkdown
        escapeHtml={false}
        renderers={{
          link: LinkRender,
          image: ImageRender,
          paragraph: ParaRender,
          html: HTMLRender,
        }}
        source={removeSpaces(props.children)}
      />
    </MdWrapper>
  )
}
