import React from 'react'

import Markdown from '../markdown'
import * as Types from '../types'
import { COMMAND } from './splash'

const article: Types.Article = {
  title: '5. MIDI Controllers',
  shortTitle: '5. MIDI Controllers',
  url: 'midi',
  feedContent: (
    <Markdown>{`
  <video remote="https://player.vimeo.com/video/417904926"/>
  
  **Goal:** *Use a midi controller with repsyps and set up your own control presets.*

  ## MIDI
  Repsyps can be used solely with your computer, but with [midi](https://en.wikipedia.org/wiki/MIDI) you can bind your controls to another physical interface like a DJ controller, faders, keyboard etc. External controllers interface with repsyps through the *control grid*, shown in the [last tutorial](/repsyps/controls). Any *location* on the control grid can be bound to any midi input, so when you move from scene to scene, that knob or pad or key on your midi controller will be able to move whatever control is currently in that location on the grid.

  ## Adding a Binding
  When you select a location on the control grid at the top of the *location details* to the left of the grid you'll find the midi settings for that location. To bind that location of the grid to a midi note or fader:
   
  - Hit \`Set Midi\` or use \`${COMMAND} M\` to mark that location as the one you want to bind to.
  - Move or press the knob or pad on your midi controller you want to bind.
  - Now the channel and note number will appear in the top right of the grid location.
  - Test it, make sure some kind of control is in that location and then mess with your controller.

  ## One Way vs Two Way Bindings
  What happens if your midi controller gets out of sync with repsyps? You can force this to happen by moving the control knob in repsyps, but not on your controller. Repsyps will notice that the controller is out of sync, and will temporarily disable the midi input until you move the fader or knob to where repsyps says it should be. While you're out of sync, a little red mark on the knob in repsyps will show where you midi input actually is. Disabling the input is necessary to keep a jump in the control value from happening accidentally.

  Some midi controllers do support *two way* midi functionality. This means that repsyps can tell them that the control value has changed and *the controller* will update to accomodate, hence two-way. You can enable two-way control for a particular location by clicking the directional control in the control location midi settings. \`Down Arrow\` means that location is one-way, \`Up and Down Arrow\` means it's two-way. Most controllers don't support two way controls, but the ones that do usually have freely spinning knobs with light indicators, or flying faders. A few examples of two-way controllers are:
   - [midi fighter twister](https://store.djtechtools.com/products/midi-fighter-twister)
   - [many behringer controllers](https://www.behringer.com/Categories/Behringer/c/Behringer?q=:catRank:division:CREA:publicProduct:true#googtrans(en|en))
   - [some of the akai ableton controllers](https://www.akaipro.com/apc-40-mkii)

  ## Global Controls
  Up to this point, any control you add will only be saved for *that* scene. This is useful since each scene can have its own unique controls. However, you may want to have some controls on *every* scene like the global volume or tempo. Use the \`lock icon\` in the top left corner of the location settings to mark that location as global. Then, no matter what scene you're in that location will have the same control. Just make sure that location won't overlap with locations being used by some scenes.

  ## Control Presets
  In the [scenes tutorial](/repsyps/scenes) we briefly looked at using some of the built-in control presets. You can also add your own custom presets. Say you have a particular combination of knobs and buttons that you really like to be able to reuse. To create a new preset:

   - Setup your controls just as you like in the control grid for the current scene.
   - Hit the \`+\` at the top right of the control presets list.
   - Type the name of your new preset and hit \`enter\`
  
  Now you can apply this preset to any scene by selecting it, then clicking \`< Apply\`.

  ## Saving your Bindings
  Repsyps will remember your midi bindings and control presets, but if you'd like to share them with someone else or have multiple configurations for different scenarios, you can save your midi bindings and controls presets in a \`.rbind\` bindings file. Use \`controls > save binding config\` and \`controls > load binding config\` to save and load these files.
 
`}</Markdown>
  ),
  tags: [],
  contentPrompt: 'written version...',
  //loadContent: () => import('./content'),
}

export default article
