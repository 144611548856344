import React from 'react'

import Markdown from '../../markdown'
import * as Types from '../../types'

const article: Types.Article = {
  title: 'Projection Mapper',
  date: new Date('June 12 2015').getTime(),
  url: 'proj-mapper',
  feedContent: (
    <Markdown>{`
    ![(1.8)](media/2015-7-12-Projection/align.jpg)

    *A simple javascript library for aligning projected images onto planar surfaces.*

    Recently, I've had the need to projection map something rendered in the browser onto a surface that isn't parallel with the projector. This is possible with existing mapping tools like resolume, but to use that in the browser you'll need something like [syphon](http://syphon.v002.info/) to copy the video over. This is too complicated and slow. Since css can apply arbitrary matrix transforms to the content of the browser it's possible to do this with no extra tools. My mapper simply allows you to click and drag the corners of your content to line them up with the corners of the surface. Nothing else needed.

    This is done by computing the [homography matrix](https://en.wikipedia.org/wiki/Homography_(computer_vision)) from the points in browser-space, to the points you define by dragging.

    Check it out [on Github](https://github.com/satchelspencer/projection-mapper).
`}</Markdown>
  ),
  tags: [],
}

export default article
