import React from 'react'

import Markdown from '../../markdown'
import * as Types from '../../types'

const article: Types.Article = {
  title: 'Rhythmogram - Tempo Analysis',
  url: 'rhytmogram',
  date: 1579503600000,
  feedContent: (
    <Markdown>{`
      ![visualization of rhythmogram (2)](media/2020-01-20-Rhythmogram/gram.jpg)
      
      *This image represents the tempo variations in the playing of [John Holt's "Strange Things"](https://www.youtube.com/watch?v=c-TghSNkVpo)*  
      
      While learning about automated loop detection in music, I came across the idea of [autocorrelation](https://en.wikipedia.org/wiki/Autocorrelation), where one compares a signal to itself with a certain temporal shift. For instance, if in a song there is a repeating phrase every 2 seconds then shifting the song by 2 seconds in either direction will be very similar to the original signal. By comparing the shifted signal to the original for a variety of possible shifts you can get a picture of the repeating patterns in a particular song. Peaks in the similarity of the shifted song correspond to the repetition of the kick drum every 1s, or the repetition of a melody once per measure at 4s etc, or even the repetition of a chorus every minute.

      My idea was to visualize how the repeating patterns would change *over the course of a song*. This is very similar in concept to a [spectrogram](https://en.wikipedia.org/wiki/Spectrogram), where one axis is time and the other is the song shift. The brightness corresponds to the shift similarity, in other words how prevalent that repetition frequency is at that point in the song. In the above example, the far left is the start of the song, the right is the end, and top and bottom correspond to repetition periods of 3s and 1s respectively. The white line in the center is the primary beat of the song. The wobbly nature of the white line shows the imperfections of the musicians as they speed up and slow down imperceptibly.
      
      A webtoy to play with this idea is forthcoming...
`}</Markdown>
  ),
  tags: ['audio', 'looping'],
}

export default article
