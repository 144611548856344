import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react'

export interface RouteContext {
  path: string
  go: (path: string, title?: string) => void
  replace: (path: string, title?: string) => void
}

const routeContext = createContext<RouteContext>({
  path: '/',
  go: () => {},
  replace: () => {},
})

const { Provider } = routeContext

export function RouteContextProvider(props: any) {
  const [path, setPath] = useState(window.location.pathname),
    handlePathChange = useCallback(() => {
      setPath(window.location.pathname)
    }, []),
    context = useMemo<RouteContext>(
      () => ({
        path,
        go: (path, title) => {
          window.history.pushState({}, title || 'elldev', path)
          handlePathChange()
        },
        replace: (path, title) => {
          window.history.replaceState({}, title || 'elldev', path)
          handlePathChange()
        },
      }),
      [path, handlePathChange]
    )

  useEffect(() => {
    window.addEventListener('popstate', handlePathChange)
    window.addEventListener('history', handlePathChange)
    return () => {
      window.removeEventListener('popstate', handlePathChange)
      window.removeEventListener('history', handlePathChange)
    }
  }, [handlePathChange])

  return <Provider value={context}>{props.children}</Provider>
}

export function useRouter() {
  return useContext(routeContext)
}
