import React from 'react'

import Markdown from '../markdown'
import * as Types from '../types'

const article: Types.Article = {
  title: '11. Advanced Track Cues',
  shortTitle: '11. Track Cues II',
  url: 'cues',
  feedContent: (
    <Markdown>{`
  <video remote="https://player.vimeo.com/video/417929807"/>
  
  **Goal:** *Use cues to control parameters, and start and end behavior.*

  ## Cues
  We've briefly mentioned cues in the [scenes tutorial](/repsyps/scenes) as a way of saving specific points in a track. They do include some extra functionality for a few specific scenarios:

  ## Start and End Behavior
  By default, triggering a cue (start behavior) will immediately jump to that cue, with no delay and the end of a cue (end behavior) will loop back to the beginning. This covers a lot of cases but you do have other options. In the cue list, each cue has a bank of 4 settings just to the right of its name. The last two are the start and end behavior. Clicking will cycle through their options:

  - Start Behavior:
    - **Trigger Immediately** the cue will start as soon as it is triggered. \`Down Arrow\` (default)
    - **Trigger on Next Grid** the cue will start on the next time grid. \`Bent Right Arrow\`
    - **Trigger at End** the cue will start once the current playback reaches its end. \`Right Arrow\`
  - End Behavior:
    - **Loop** the cue will start over once its playback ends \`Loop Icon\` (default)
    - **Play Next** the cue will jump to the next cue once it finishes \`Right Step Arrow\`
    - **Stop** the cue will stop playing once it finishes \`Square\`

  ## Track Parameters
  Cues can also be used to store and trigger changes in track parameters like volume and filtering. This may be useful for triggering an instantaneous transition or saving the starting state of a track when using absolute controls. By default, cues don't change the track volume or filter when they are triggered, only the track playback location. You can enable the volume and filter parameters in the cue by clicking and toggling the volume or filter icons for a cue in the cue list. Oftentimes these changes are better handled with relative controls, but the option is open to you.
`}</Markdown>
  ),
  tags: [],
  contentPrompt: 'written version...',
  //loadContent: () => import('./content'),
}

export default article
