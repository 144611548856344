import React from 'react'

import Markdown from '../markdown'
import * as Types from '../types'

const article: Types.Article = {
  title: '8. Audio Outputs',
  shortTitle: '8. Audio Outputs',
  url: 'outputs',
  feedContent: (
    <Markdown>{`
  <video remote="https://player.vimeo.com/video/417908134"/>
  
  **Goal:** *Configure repsyps' audio outputs, and pre-listening.*

  ## Main Audio Output
  Repsyps uses your operating system's audio interface, and any outputs that support at least \`2 channel 44.1khz\` output. You can see a list of compatible outputs, under the \`output\` menu. Selecting an output here redirects repsyps main output to that interface. If you don't see an output listed here, it's likely incompatible with repsyps' requirements.

  ## Preview Output and Pre-Listening
  Repsyps optionally has a secondary output intended for cueing tracks, or pre-listening. You can select which interface should be used for this in the \`preview\` menu. In practice, you'll need at the very least one extra audio interface or sound card to do this. Many DJ controllers include an audio interface that repsyps can use. Once you have a preview output defined, you'll be able to preview any track by clicking the headphones preview button to the left of the waveform. When the track is in preview, it will be played on the preview audio interface even if it's volume is down in the main output. This allows you to cue up a track that's currently muted by your controls. You can even set up the time grid using the preview output on a track you've never seen, before you transition to it.
`}</Markdown>
  ),
  tags: [],
  contentPrompt: 'written version...',
  //loadContent: () => import('./content'),
}

export default article
