import React, { memo, useRef } from 'react'
import ctyled, { active } from 'ctyled'

import { useRouter } from './router'

import Icon from './icons/icon'
import useMeasure from './measure'

const Grid = ctyled.div.styles({}).extendSheet`
  display: grid; 
  
  @media (orientation: landscape){
    width:80vh;
    height:80vh;
  }
  @media (orientation: portrait){
    width:80vw;
    height:80vw;
  }
`

const GRID_BASIS = 25,
  i2p = (int: number) => GRID_BASIS * int + '%'

const CellWrapper = ctyled.div.attrs({ x: 0, y: 0, w: 0, h: 0 }).styles({
  padd: 0.75,
}).extend`
  position:absolute;
  ${(_, { x, y, w, h }) => `
    top:${i2p(y)};
    left:${i2p(x)};
    width:${i2p(w)};
    height:${i2p(h)};
  `}
`

const Cell = ctyled.div.class(active).styles({
  hover: 0.3,
  bg: true,
  padd: 4,
  color: (c) => c.nudge(0.07),
  borderColor: (c) => c.contrast(-0.3),
  align: 'center',
  justify: 'center',
  gutter: 1,
  column: true,
  size: (s) => s * 0.9,
}).extend`
width:100%;
height:100%;
`

const Main = Cell.styles({
  hover: false,
  color: (c) => c.invert().contrast(-0.15).nudge(0.05),
})

const VertInner = ctyled.div.styles({
  column: true,
  gutter: 0.5,
  align: 'flex-start',
})

const Long = Cell.styles({
  gutter: 2,
  column: false,
})

const MainHead = ctyled.div.styles({
  size: (s) => s * 2,
}).extendSheet`
  font-weight:bold;
`

const CellDesc = ctyled.div.styles({}).extendSheet`text-align:center;`

const CellDescL = ctyled.div.styles({})

const Link = ctyled.a.extendSheet`
  display:inline;
  text-decoration:underline;
`

const Contact = ctyled.div.styles({
  padd: 0.5,
  size: (s) => s * 0.8,
}).extend`
  opacity:0.8;
`

const Splash = memo(() => {
  const { go } = useRouter(),
    gref = useRef<any>(null),
    { width } = useMeasure(gref)

  return (
    <Grid inRef={gref} styles={{ size: width / 50 }}>
      {width > 0 && (
        <>
          <CellWrapper x={0} y={0} w={2} h={2}>
            <Main>
              <Icon name="star" style={{ width: '40%' }} />
              <MainHead>ELLDEV</MainHead>
              <CellDesc style={{ userSelect: 'text' }}>
                <i>
                  creations and ideas of satchel spencer
                  <Contact>
                    satchelspencer@gmail.com –&nbsp;
                    <Link
                      style={{ textDecoration: 'underline' }}
                      href="https://github.com/satchelspencer"
                    >
                      github
                    </Link>
                  </Contact>
                </i>
              </CellDesc>
            </Main>
          </CellWrapper>
          <CellWrapper x={2} y={1} w={2} h={2}>
            <Cell onClick={() => go('/repsyps')}>
              <Icon name="repsyps" style={{ width: '25%', transform: 'rotate(45deg)' }} />
              <MainHead>repsyps</MainHead>
              <CellDesc>
                realtime playback and syncronization of periodic signals. or music i
                suppose...
              </CellDesc>
            </Cell>
          </CellWrapper>
          <CellWrapper x={2} y={0} w={1} h={1}>
            <Cell onClick={() => go('/feed/video-automata')}>
              <Icon name="vauto" style={{ width: '70%' }} />
              <CellDesc>video automata</CellDesc>
            </Cell>
          </CellWrapper>
          <CellWrapper x={3} y={0} w={1} h={1}>
            <Cell onClick={() => go('/feed/bifsi')}>
              <Icon name="bifsi" style={{ width: '70%' }} />
              <CellDesc>convnet building identification</CellDesc>
            </Cell>
          </CellWrapper>
          <CellWrapper x={0} y={2} w={1} h={1}>
            <Cell onClick={() => go('/feed/ttrl')}>
              <Icon name="ttrl" style={{ width: '70%' }} />
              <CellDesc>live concrete poetry</CellDesc>
            </Cell>
          </CellWrapper>
          <CellWrapper x={1} y={2} w={1} h={1}>
            <Cell onClick={() => go('/feed/dctgan')}>
              <Icon name="dct" style={{ width: '60%' }} />
              <CellDesc>DCT GAN</CellDesc>
            </Cell>
          </CellWrapper>
          <CellWrapper x={0} y={3} w={1} h={1}>
            <Cell onClick={() => go('/feed/rhytmogram')}>
              <Icon name="rhyt" style={{ width: '50%' }} />
              <CellDesc>rhythmic analysis</CellDesc>
            </Cell>
          </CellWrapper>
          <CellWrapper x={1} y={3} w={2} h={1}>
            <Long onClick={() => go('/feed/ctyled')}>
              <Icon name="ctyled" style={{ width: '50%', flex: 0.5 }} />
              <VertInner styles={{ flex: 1.5 }}>
                <MainHead>ctyled</MainHead>
                <CellDescL>relative color in interface design</CellDescL>
              </VertInner>
            </Long>
          </CellWrapper>
          <CellWrapper x={3} y={3} w={1} h={1}>
            <Cell onClick={() => go('/feed')}>
              <br />
              <Icon name="dots" style={{ width: '35%' }} />
              <CellDesc>everything</CellDesc>
            </Cell>
          </CellWrapper>
        </>
      )}
    </Grid>
  )
})

export default Splash
