import React from 'react'

import Markdown from '../markdown'
import * as Types from '../types'

const article: Types.Article = {
  title: '4. Custom Track Controls',
  shortTitle: '4. Custom Controls',
  url: 'controls',
  feedContent: (
    <Markdown>{`
  <video remote="https://player.vimeo.com/video/417888479"/>
  
  **Goal:** *Add custom shortcuts to control tracks and scenes in more interesting ways.*

  ## Motivation
  Up till now, we've only controlled track parameters like volume and filter using sliders in the *track details pane* or by using some basic control presets. This covers a lot of use cases (like crossfades between scenes), but you can also create *your own* controls to do pretty much anything. 

   - Multiple tracks and sources controlled by a single knob? *yes.* 
   - Add buttons for specific cues? *sure.* 
   - Some 2 track setup you're probably used to? *yes whatever* 
   
  Make your own mixing board.

  ## Adding a Custom Control
  *If you haven't already, set up a couple tracks with time grids to play with as detailed in [the first tutorial](/repsyps/grid).*

  Clicking on an individual location in the *control grid* will select that location (highlighted a little red), and show it's details in the location details pane on the left side of the grid. Now let's add a control to this location. 

  1. Click on \`Add Control\` in the location details or use the hotkey \`A\` to start a control selection. \`Escape\` will cancel the selection.
  2. Areas of the user interface that can be controlled will be highlighted red, clicking on them will add that control to the grid location you have selected.
  3. Click on your first track's name in the *tracklist*

  Now you'll have a knob labeled *Track 1 Volume* in the grid location you selected. Play the track with \`spacebar\`, and manipulate the knob to control the track volume. *Clicking and dragging the knob up and/or right will increase the knob value, down and/or left will decrease the knob value.* This will increase and decrease the volume of the track as you would expect.

  ## Positive vs Negative Controls
  The one control we've added has a *positive* relationship to the track parameter that it is bound to. This means that when the knob is all the way up the track parameter (in this case volume) will be all the way up, and turning the knob down will turn the volume down. In some cases it's helpful to reverse this. In the grid location details to the left of the grid, each parameter that location is controlling will have a little up arrow or down arrow. Clicking on the up arrow will change that control from a *positive* to a *negative* control. Once you do this, turning the knob will be "backwards". All the way up will mute the track, and turning it all the way down turns it's volume all the way up. 
  
  *Why do this? See below...*

  ## Crossfade Between Tracks
  Now we've got a negative control in our grid location let's add a second control to *that same location*. As before, click \`Add Control\` or \`A\` then bind that control to the second track. Now our knob controls two tracks. Since one of the track controls is positive, and one is negative the knob will act as a *crossfader*. Turning the knob will fade from one track to another. Start both tracks playing, and try this out.

  You aren't  by any means limited to crossfading between whole tracks. You can also bind controls to track sources (like the acapella), and even filters. For instance, you could make one knob that transitions one track from its normal version to a mashup of that track and another. Here's what that might look like:
   - **Track 1 source 1 (Positive)** The original song
   - **Track 1 source 2 (Negative)** The acapella synced with the original
   - **Track 3 (Negative)** Another instrumental that we can fade in

  More discussion about how controls work, and other uses of controls can be found in the [advanced controls tutorial](/repsyps/adv-controls)
`}</Markdown>
  ),
  tags: [],
  contentPrompt: 'written version...',
  //loadContent: () => import('./content'),
}

export default article
