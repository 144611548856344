import React from 'react'

import Markdown from '../../markdown'
import * as Types from '../../types'

const article: Types.Article = {
  title: 'Building Contour Identification',
  url: 'bifsi',
  date: new Date('May 8 2018').getTime(),
  feedContent: (
    <>
      <Markdown>{`
        ![(1.6)](media/2018-06-08-Bifsi/bifsi.jpg)

        Created for my senior capstone project for [Trimble](https://www.trimble.com/), this system aims to aid contributors to the open source OSM ([open streetmap](https://www.openstreetmap.org/#map=5/38.007/-95.844)) database by:

         - Using convolutional neural nets to find buildings in satellite imagery that aren't included in existing OSM data.
         - Locating the buildings within an individual image with *fully convolutional networks*.
         - Automatically determining the contours for individual buildings.
         - Integrating with [iD](http://ideditor.com/) (an OSM editor).

        source [on github](https://github.com/satchelspencer/building-identification/)
      `}</Markdown>
    </>
  ),
  tags: [],
  loadContent: () => import('./content'),
}

export default article
