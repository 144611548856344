import React, { memo, useState, useEffect, useCallback, Fragment } from 'react'
import ctyled, { active, inline } from 'ctyled'
import format from 'dateformat'

import { useRouter } from './router'

import * as Types from './types'

const FeedItemHead = ctyled.div.styles({
  padd: 0,
  justify: 'space-between',
  align: 'center',
})

const FeedItemTitle = ctyled.div.styles({
  size: (s) => s * 1.5,
}).extendSheet`
  font-weight:bold;
  cursor:pointer;
  overflow:hidden;
  display:block;
  white-space:nowrap;
  text-overflow:ellipsis;
  &:hover{
    text-decoration:underline;
  }
`

const FeedItemDate = ctyled.div.styles({
  color: (c) => c.contrast(-0.1),
}).extend`
  font-style:italic;
`

const ReadMore = ctyled.div
  .class(inline)
  .class(active)
  .styles({
    hover: 1,
    bg: false,
    size: (s) => s * 1.1,
    color: (c) => c.contrast(-0.4),
  }).extendSheet`
text-decoration:underline;
`

interface ArticleProps {
  article: Types.Article
  articleId: string
  selected?: boolean
  route: string
}

const Article = memo((props: ArticleProps) => {
  const { article, selected } = props,
    { go } = useRouter(),
    [content, setContent] = useState<any>(null),
    [loading, setLoading] = useState(false),
    loadContent = useCallback(async () => {
      if (article.loadContent) {
        setLoading(true)
        const content = await article.loadContent()
        setContent(content.default)
        setLoading(false)
      }
    }, [article]),
    [open, setOpen] = useState(false)

  useEffect(() => {
    if (selected) loadContent()
  }, [selected, loadContent])

  return article.rawContent ? (
    article.rawContent
  ) : (
    <Fragment>
      <FeedItemHead>
        <FeedItemTitle onClick={() => go(`${props.route}/${article.url}`)}>
          {article.title}
        </FeedItemTitle>
        {article.date && (
          <FeedItemDate>{format(new Date(article.date), 'mmm dS, yyyy')}</FeedItemDate>
        )}
      </FeedItemHead>
      {article.feedContent}
      {article.loadContent && !open && (
        <ReadMore
          onClick={() => {
            go(`${props.route}/${article.url}`)
            loadContent()
            setOpen(true)
          }}
        >
          {open && loading ? 'loading...' : article.contentPrompt || '[read more...]'}
        </ReadMore>
      )}
      {open && content}
    </Fragment>
  )
})

export default Article
