import React from 'react'

import Markdown from '../../markdown'
import * as Types from '../../types'

const article: Types.Article = {
  title: 'Video Cellular Automaton',
  url: 'video-automata',
  date: new Date('April 16 2019').getTime(),
  feedContent: (
    <>
      <Markdown>{`
        <gif src="2019-04-16-Vautomata/autogif"/>

        *video demonstartion of the video cellular automaton. [full video here](https://vimeo.com/419640164)*

        This is a technical summary of my process in creating a [cellular automaton](https://en.wikipedia.org/wiki/Cellular_automaton) that displays its state as a grid of videos. Transitions between states are realized as the movements of individual dancers between neighboring cells. This was created in collaboration with Michelle Ellsworth as part of her piece "PVSN". Performed at [On The Boards Seattle](https://www.ontheboards.org/special-events/michelle-ellsworth-the-rehearsal-artist-and-post-verbal-social-network) in April 2019

        source is available [on github](https://github.com/satchelspencer/live-automata)
      `}</Markdown>
    </>
  ),
  tags: [],
  loadContent: () => import('./content'),
}

export default article
