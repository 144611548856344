import React from 'react'

import Markdown from '../../markdown'
import * as Types from '../../types'

const article: Types.Article = {
  title: 'Not Another CMS',
  date: new Date('Dec 04 2014').getTime(),
  url: 'cms',
  feedContent: (
    <Markdown>{`
    ![(1.55)](media/2014-12-04-Cms/wide.png)

    *A CMS aimed at precise control for designers, not consumers.*

    Intended for flexibility, this CMS is essentially just a wrapper on common CSS design principles. Position, styles, alignment and responsiveness, can all be controlled through the gui. As an editor, it is *what you see is what you get*. You can manipulate your elements directly on the page, or through the hierarchical element layer view.

    The CMS also includes utilities for managing your media and other files on the server. Installation is as simple as copying the source to your server, then you can get started creating your static site.
    
    Source on [Github](https://github.com/satchelspencer/elldev)
`}</Markdown>
  ),
  tags: [],
}

export default article
