import React from 'react'

import Markdown from '../../markdown'
import * as Types from '../../types'

const article: Types.Article = {
  title: 'DCTGAN - GAN Memory Reduction',
  url: 'dctgan',
  date: new Date('11/24/2019').getTime(),
  feedContent: (
    <Markdown>{`
      ![(2)](media/2019-11-24-DCTGAN/gan.jpg)

      In my past work with generative ai (specifically [gans](https://en.wikipedia.org/wiki/Generative_adversarial_network)) my greatest bottleneck in performance hasn't been computational speed, but rather memory footprint. When trying to increase the resolution of the output image, the number of parameters in the model grows to such an extent that the batch size must be too small to be useful. I've been experimenting with a new approach to reducing this memory requirement by using existing image compression techniques, letting the model learn to generate the *compressed representation* of an image.

      I call this approach *DCTGAN* because it uses the [discrete cosine transform](https://en.wikipedia.org/wiki/Discrete_cosine_transform) (the underlying mechanism behind jpeg compression). The model learns to generate the images in the frequency domain, and only uses comparatively low frequencies, effectively discarding the vast majority of the image data. In this way the model can 'pay attention' to the low frequency aspects of the image that contribute most to the overall perception of the image.

      Initial results have pretty significant jpeg style artifacting, but do indeed reduce the memory footprint of the model by a factor of 4 with acceptable results. 
      
      Source code is available [on github](https://github.com/satchelspencer/dctgan)
`}</Markdown>
  ),

  tags: ['audio', 'looping'],
}

export default article
