import React from 'react'

import Markdown from '../../markdown'
import * as Types from '../../types'

const article: Types.Article = {
  title: 'Idea Matrix',
  date: new Date('Feb 12 2017').getTime(),
  url: 'idea-matrix',
  feedContent: (
    <Markdown>{`
    ![(1.8)](media/2017-2-12-Matrix/matrix.png)
    *Related topics clustered and shown as an adjacency matrix.*

    Commissioned as a tool for professors to use in class discussion, [the idea matrix](http://michelleellsworth.com/idea-matrix/) allows users to add an arbitrary set of categories, which can be connected to any other category. Then before discussion, students would find links between categories, and describe them at their intersections. This creates a [graph](https://en.wikipedia.org/wiki/Graph_theory) of interconnected ideas displayed as an adjacency matrix. Users can then automatically cluster the categories and find new connections as a means of facilitating discussion.

    Source on [github](https://github.com/satchelspencer/idea-matrix).
`}</Markdown>
  ),
  tags: [],
}

export default article
