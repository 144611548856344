import React from 'react'
import ctyled from 'ctyled'
import { useRouter } from './router'

import Splash from './splash'
import ArticlesFeed from './articles'
import RepsypsFeed from './repsyps'
import RepsypsSplash from './repsyps/splash'

const Wrapper = ctyled.div.styles({
  align: 'center',
  justify: 'center',
  size: (s) => s * 1,
  color: (c) =>
    c
      .as([
        'black',
        { color: '#424242', pos: 0.3 },
        { color: '#eeecec', pos: 0.95 },
        'white',
      ])
      .absLum(0.9)
      .contrast(0.2),
  bg: true,
}).extendSheet`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  min-height:100%;
  height:auto;
  font-family: 'PT Serif', serif;
`

const App = () => {
  const { path } = useRouter()

  return (
    <Wrapper>
      {path.indexOf('/feed') === 0 && <ArticlesFeed />}
      {path.indexOf('/repsyps') === 0 &&
        (path === '/repsyps' ? <RepsypsSplash /> : <RepsypsFeed />)}
      {path === '/' && <Splash />}
    </Wrapper>
  )
}

export default App
