import React from 'react'
import ctyled from 'ctyled'

const DemoBody = ctyled.div.styles({
  bg: true,
  flex: 1,
  height: 25,
  rounded: true,
  column: true,
  border: 1,
  lined: true,
})

const DemoHeader = ctyled.div.styles({
  height: 1,
  bg: true,
  color: c => c.nudge(0.1),
  align: 'center',
  padd: 0.5,
  justify: 'center',
})

const ExitControls = ctyled.div.styles({
  gutter: 1,
  height: 1,
  align: 'center',
  padd: 0.5,
}).extend`
  position:absolute;
  left:0;
`

const ExitControl = ctyled.div.styles({
  width: 0.5,
  height: 0.5,
  color: c => c.invert(),
  bg: true,
}).extendSheet`
  border-radius:50%;
`

const Text = ctyled.div.styles({
  height: 0.2,
  bg: true,
  color: c => c.invert(),
})

const Empty = ctyled.div

const Body = ctyled.div.styles({
  flex: 1,
})

const Sidebar = ctyled.div.styles({
  width: 2,
  bg: true,
  color: c => c.invert(),
  column: true,
  gutter: 1,
  align: 'center',
  padd: 1,
})

const SidebarItem = ctyled.div.styles({
  width: 1,
  height: 1,
  color: c => c.invert(),
  bg: true,
}).extendSheet`
border-radius:50%;
`

const Main = ctyled.div.styles({
  flex: 1,
  column: true,
  lined: true,
  borderColor: c => c.contrast(0.1),
})

const Top = ctyled.div.styles({
  flex: 1,
  lined: 1,
  borderColor: c => c.contrast(0.1),
})

const Sources = ctyled.div.styles({
  flex: 1,
  bg: true,
  color: c => c.nudge(0),
  column: true,
  lined: true,
})

const Output = ctyled.div.styles({
  flex: 1,
  bg: true,
  padd: 1.5,
  color: c => c.nudge(-0.05),
})

const Timeline = ctyled.div.styles({
  flex: 1,
  bg: true,
  color: c => c.nudge(0.05),
  padd: 1,
}).extendSheet`
  overflow:hidden;
`

const TimelineInner = ctyled.div.styles({
  flex: 1,
  height: '100%',
})

const TimelineItem = ctyled.div.styles({
  color: c => c.contrast(-0.3).nudge(0.1).invert(),
  bg: true,
  rounded: 0.5,
}).extendSheet`
  position:absolute;
  height:20%;
`

const Source = ctyled.div.styles({
  height: 1.5,
  padd: 0.7,
  gutter: 1,
  bg: true,
  color: c => c.nudge(0.05),
  align: 'center',
})

const SourceImg = ctyled.div.styles({
  width: 1,
  height: 1,
  bg: true,
  color: c => c.nudge(-0.05),
})

const Vtext = ctyled.div.styles({
  flex: 1,
  gutter: 0.5,
  column: true,
})

const OutputInner = ctyled.div.styles({
  bg: true,
  color: c => c.nudge(0.1),
  height: '100%',
  width: '100%',
  border: 1
})

export default function ColorDemo() {
  return (
    <DemoBody>
      <DemoHeader>
        <ExitControls>
          <ExitControl styles={{ color: c => c.as(['red']) }} />
          <ExitControl styles={{ color: c => c.as(['orange']) }} />
          <ExitControl styles={{ color: c => c.as(['rgb(53,204,75)']) }} />
        </ExitControls>
        <Text style={{ width: '25%' }} />
        <Empty />
      </DemoHeader>
      <Body>
        <Sidebar>
          <SidebarItem />
          <SidebarItem />
          <SidebarItem />
          <SidebarItem />
        </Sidebar>
        <Main>
          <Top>
            <Sources>
              <Source>
                <SourceImg />
                <Vtext>
                  <Text style={{ width: '100%', height: 2 }} />
                  <Text style={{ width: '60%', height: 2 }} />
                </Vtext>
              </Source>
              <Source>
                <SourceImg />
                <Vtext>
                  <Text style={{ width: '100%', height: 2 }} />
                  <Text style={{ width: '100%', height: 2 }} />
                  <Text style={{ width: '20%', height: 2 }} />
                </Vtext>
              </Source>
              <Source>
                <SourceImg />
                <Vtext>
                  <Text style={{ width: '100%', height: 2 }} />
                  <Text style={{ width: '60%', height: 2 }} />
                </Vtext>
              </Source>
            </Sources>
            <Output>
              <OutputInner />
            </Output>
          </Top>
          <Timeline>
            <TimelineInner>
              <TimelineItem style={{ top: 0, left: 0, width: '60%' }} />
              <TimelineItem style={{ top: 0, left: '61%', width: '40%' }} />
              <TimelineItem style={{ top: '25%', left: '-50%', width: '60%' }} />
              <TimelineItem style={{ top: '25%', left: '40%', width: '60%' }} />
              <TimelineItem style={{ top: '50%', left: '5%', width: '60%' }} />
              <TimelineItem style={{ top: '50%', left: '85%', width: '60%' }} />
              <TimelineItem style={{ top: '75%', left: '15%', width: '20%' }} />
              <TimelineItem style={{ top: '75%', left: '55%', width: '30%' }} />
            </TimelineInner>
          </Timeline>
        </Main>
      </Body>
    </DemoBody>
  )
}
